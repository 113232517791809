<template>
    <div>
        <v-layout row class="pt-2">
            <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.nameAndBuyerAccess') }}</v-flex>
            <v-flex xs6 lg3 md4 py-1 class="ml-7">{{ $t('message.file') }}</v-flex>
        </v-layout>
        <v-layout row class="pt-1">
            <v-flex pl-5 py-2 class="xs6 lg2 md3">
                <div class="d-flex flex-row align-center">
                    <div>
                        <v-icon class="pr-2">far fa-file</v-icon>
                    </div>
                    <div class="text-no-wrap">{{ $t('message.fileName') }}</div>
                    <div style="width: 100%" class="text-right">
                        <v-icon color="red darken-1">mdi-lock-outline</v-icon>
                    </div>
                </div>
            </v-flex>
            <v-flex py-2 class="xs6 lg3 md4">
                <div class="data-holder"></div>
            </v-flex>
            <div class="d-flex flex-row align-center">
                <v-btn color="default" small>{{ $t('message.upload') }}</v-btn>
            </div>
        </v-layout>
        <v-layout row class="pt-1">
            <v-flex pl-5 py-2 class="xs6 lg2 md3">
                <div class="d-flex flex-row align-center">
                    <div>
                        <v-icon class="pr-2">far fa-file</v-icon>
                    </div>
                    <div class="text-no-wrap">{{ $t('message.fileName') }}</div>
                    <div style="width: 100%" class="text-right">
                        <v-icon color="red darken-1">mdi-lock-outline</v-icon>
                    </div>
                </div>
            </v-flex>
            <v-flex py-2 class="xs6 lg3 md4">
                <div class="data-holder"></div>
            </v-flex>
            <div class="d-flex flex-row align-center">
                <v-btn color="default" small>{{ $t('message.upload') }}</v-btn>
            </div>
        </v-layout>
    </div>
</template>

<script>
export default {
    name: "FileList"
}
</script>

<style scoped>
@keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0.4; }
    100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.4; }
    100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.4; }
    100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.4; }
    100% { opacity:1; }
}
.data-holder {
    -webkit-animation: flickerAnimation 3s infinite;
    -moz-animation: flickerAnimation 3s infinite;
    -o-animation: flickerAnimation 3s infinite;
    animation: flickerAnimation 3s infinite;
    height: 32px !important;
    border-radius: 16px;
    background-color: lightgray;
    margin-top: 5px;
}
</style>